/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx, css } from '@emotion/react';
import { ContextualAnalyticsData, SCREEN, FireScreenAnalytics } from '@atlassian/analytics-bridge';
import Hero from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/hero';
import MetaTags from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/meta-tags';
import { normalizeSourceName } from '../utils/analytics';
import { getPageDescription, getPageTitle } from '../utils/constants';
import titleify from '../utils/titleify';
import { token } from '@atlaskit/tokens';
import { gutterStyles } from '@af/design-system-docs-ui';

const contentStyles = css({
	marginBlock: token('space.400', '32px'),
});

type OverviewPageProps = {
	title: string;
	description?: string;
	heroImageSrc?: {
		light: string;
		dark: string;
	};
	categorySlug: string;
	entries: Array<any>;
	tileColor?: string;
	defaultIcon?: string;
	guidelineCategory?: string;
	children: ReactNode;
};

const OverviewPage = ({
	title,
	description,
	heroImageSrc,
	categorySlug,
	entries,
	children,
}: OverviewPageProps) => {
	const pageTitle = getPageTitle(title);
	const pageDescription = getPageDescription(title);

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName="index"
			attributes={{
				name: `${normalizeSourceName(title)}Home`,
				entriesCount: entries.length,
			}}
		>
			<MetaTags
				title={pageTitle}
				description={pageDescription}
				categorySlug={titleify(categorySlug)}
			/>
			<FireScreenAnalytics key={title} />
			<Hero
				css={{ gridArea: 'hero' }}
				headline={title}
				description={description}
				imageSrc={heroImageSrc}
				imageDimensions={{
					width: '48rem',
					height: '40rem',
				}}
			/>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={[contentStyles, gutterStyles]}>{children}</div>
		</ContextualAnalyticsData>
	);
};

export default OverviewPage;
